<template>
  <div class="server-detail">
    <div class="c-width">
      <div class="pwd-box cf">
        <pwd class="fl" :pwdText="'我们的服务'" :current="tempDetail.title+'详情'" />
      </div>
      <div class="opt-box cf">
        <div class="l-box fl">
          <h4>{{tempDetail.title}}</h4>
          <!-- <p>{{tempDetail.brief}}</p> -->
        </div>
        <div class="btn fr" @click="tjXq">提交需求</div>
      </div>
      <div class="context-box">
        <!-- <h3>{{tempDetail.title}}</h3> -->
        <div class="text" v-html="tempDetail.content"></div>
      </div>
    </div>
  </div>
</template>
<script>
import mixins from "../mixins";
import pwd from "@/component/pwd/src/pwd";
export default {
  mixins,
  components: {
    pwd,
  },
};
</script>
<style scoped>
@import "../server-detail.css";
</style>